import {Box} from "@mui/material";
import React from "react";
import InfoTooltip from "../Tooltip/InfoTooltip";

const RatioTypeLabel = ({sign, tooltipContent}) => {

  return (
    <Box sx={{display: "flex", justifyContent: "center"}}>
      <Box fontSize="150%">{sign}</Box>
      <InfoTooltip content={tooltipContent}/>
    </Box>
  );
};

export default RatioTypeLabel;