import {FormControl, MenuItem, Select} from "@mui/material";
import {IntlContext} from "../../contexts/IntlContext";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";

const LocaleSelector = () => {

  const context = useContext(IntlContext);
  const navigate = useNavigate();

  const onChange = (event) => {
    navigate('/' + event.target.value);
  };

  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        inputProps={{'aria-label': 'Locale'}}
        value={context.locale}
        onChange={onChange}
      >
        <MenuItem value="en">EN - English</MenuItem>
        <MenuItem value="pl">PL - Polski</MenuItem>
      </Select>
    </FormControl>
  );
}

export default LocaleSelector;
