import {Box} from "@mui/material";

const CarbChip = ({text, display}) => {
  return (
    <Box
      display={display}
      align="center"
      sx={{bgcolor: "rgba(255,167,44,0.79)", px: 0.75, py: 0.5, pb: 0.25, border: 2, borderColor: 'rgb(0 0 0 / 16%)', borderRadius: 2}}
    >
      {text}
    </Box>
  );
};

export default CarbChip;