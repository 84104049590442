import {Box} from "@mui/material";
import React from "react";
import Settings from "./../component/Settings/Settings";
import Calculator from "./../component/Calculator";
import {SettingsContext} from "../contexts/SettingsContext";

const Index = () => {

  const settings = React.useContext(SettingsContext);

  return (
    <Box>
      {(settings.show || !settings.isComplete) && <Settings></Settings>}
      {(!settings.show && settings.isComplete) && <Calculator></Calculator>}
    </Box>
  );
};

export default Index;