import RatioFactory from "./RatioFactory";
import MultiplyRatio from "../Ratio/MultiplyRatio";
import {parseInputToFloat} from "../../utils/Number";

class MultiplyRatioFactory extends RatioFactory {

  createFromInput(value: string): MultiplyRatio {
    return new MultiplyRatio(parseInputToFloat(value));
  }

  presentSign(): string {
    return MultiplyRatio.sign;
  }

}

export default MultiplyRatioFactory;