import BloodSugarUnit from "./BloodSugarUnit";

class Mmoll extends BloodSugarUnit {

  readonly symbol = "mmol/L";

  readonly mgdlAmount = 18;

  presentRounded(value: number): string {
    return String(Math.round((value + Number.EPSILON) * 10) / 10);
  }
}

export default Mmoll;