import {createContext, useEffect, useState} from 'react';
import SettingsRepository from "../repository/SettingsRepository";

export const SettingsContext = createContext({
  precision: null,
  show: false,
});

export const SettingsProvider = ({ children }) => {
  const repository = new SettingsRepository();
  const [precision, setPrecision] = useState(repository.getPrecision());
  const [carbUnit, setCarbUnit] = useState(repository.getCarbUnit());
  const [carbPortionAmount, setCarbPortionAmount] = useState(repository.getCarbPortionAmount());
  const [ratioType, setRatioType] = useState(repository.getRatioType());
  const [sensitivityFactorSuggestion, setSensitivityFactorSuggestion] = useState(repository.getSensitivityFactorSuggestion());
  const [bloodSugarUnit, setBloodSugarUnit] = useState(repository.getBloodSugarUnit());
  const [show, setShow] = useState(false);
  const checkIsComplete = () => {
    return null!== bloodSugarUnit && null !== precision && null !== carbUnit && null !== ratioType;
  };
  const [isComplete, setIsComplete] = useState(checkIsComplete());

  const value = {
    precision,
    setPrecision,
    carbUnit,
    setCarbUnit,
    carbPortionAmount,
    setCarbPortionAmount,
    ratioType,
    setRatioType,
    sensitivityFactorSuggestion,
    setSensitivityFactorSuggestion,
    setBloodSugarUnit,
    bloodSugarUnit,
    show,
    setShow,
    isComplete
  };

  useEffect(() => {
    repository.savePrecision(precision);
    setIsComplete(checkIsComplete())
  }, [precision])

  useEffect(() => {
    setIsComplete(checkIsComplete())
    repository.saveCarbUnit(carbUnit);
  }, [carbUnit])

  useEffect(() => {
    repository.saveCarbPortionAmount(carbPortionAmount);
  }, [carbPortionAmount])

  useEffect(() => {
    setIsComplete(checkIsComplete())
    repository.saveRatioType(ratioType);
  }, [ratioType])

  useEffect(() => {
    repository.saveSensitivityFactorSuggestion(sensitivityFactorSuggestion);
  }, [sensitivityFactorSuggestion])

  useEffect(() => {
    setIsComplete(checkIsComplete())
    repository.saveBloodSugarUnit(bloodSugarUnit);
  }, [bloodSugarUnit])

  useEffect(() => {
    if (false === isComplete) {
      setShow(true);
    }
  }, [isComplete])

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
