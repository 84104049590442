import React, {useEffect} from "react";
import {IntlContext} from "../contexts/IntlContext";
import {redirect, useLoaderData} from "react-router-dom";
import Locale from "../model/Locale";
import IntlRepository from "../repository/IntlRepository";

const repository = new IntlRepository();

export function LocaleGuardLoader({params}) {

  const intlModel = repository.getIntl();

  if (undefined === params.locale) {
    return redirect('/' + intlModel.locale + window.location.pathname);
  }

  if (!Object.values(Locale).includes(params.locale)) {
    throw new Response("", {status: 404});
  }

  return {locale: params.locale};
}

const LocaleGuard = ({children}) => {

  const context = React.useContext(IntlContext);
  const {locale} = useLoaderData();

  useEffect(() => {
    context.selectLocale(locale)
  }); // no [] to run everytime state gets updated

  return (
    <>{children}</>
  )
}

export default LocaleGuard;