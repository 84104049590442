import {parseInputToFloat} from "../utils/Number";
import BloodSugarUnit from "./BloodSugarUnit/BloodSugarUnit";
import Mgdl from "./BloodSugarUnit/Mgdl";

class BloodSugar {
  public readonly value;
  public readonly unit;

  constructor(value: number, unit: BloodSugarUnit) {
    this.value = value;
    this.unit = unit;
  }

  static fromInput(value: string, unit: BloodSugarUnit) {
    return new this(parseInputToFloat(value), unit)
  }

  presentWithUnit() {
    return String(this.value) + ' ' + this.unit.symbol;
  }
}

export default BloodSugar;