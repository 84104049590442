import Ratio from "./Ratio";

class MultiplyRatio extends Ratio {
  static readonly sign = '×';

  presentWithSign() {
    return MultiplyRatio.sign + ' ' + String(this.value);
  }

  use(): number {
    return this.value;
  }
}

export default MultiplyRatio;