import './NavBar.css';
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import React from "react";
import {SettingsContext} from "../contexts/SettingsContext";

const NavBar = () => {

  const settings = React.useContext(SettingsContext);

  const toggleSettings = () => {
    settings.setShow(!settings.show);
  }

  return (
    <AppBar position="static" color="default" sx={{mb: 4, alignItems:"center"}}>
      <Toolbar variant="dense" sx={{my: 0.5}}>
        <IconButton disabled={!settings.isComplete} onClick={toggleSettings} edge="start" aria-label="menu" sx={{ mr: 2 }}>
          <SettingsIcon />
        </IconButton>
        <img alt="logo" src="/img/logo-28.png"/>
        <Typography id="logo-brand-name" color="#198754" component="div" sx={{fontSize: "1.25rem"}}>&nbsp;InsulinMath</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;