import English from '../lang/en.json';
import Polish from '../lang/pl.json';
import Locale from "./Locale";

class IntlModel {

  public readonly locale: Locale;

  public readonly messages;

  constructor(locale: Locale) {
    this.locale = locale;
    this.messages = Locale.PL === locale ? Polish : English;
  }

  static fromUnsafe(value: any) {
    if (Locale.PL === value) {
      return new IntlModel(value);
    }

    return new IntlModel(Locale.EN);
  }
}

export default IntlModel;
