import React, {useEffect, useState} from "react";
import {SettingsContext} from "../../contexts/SettingsContext";
import {Box, FormControlLabel, FormLabel, Radio, RadioGroup, Slider, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";

const BloodSugarUnitSettings = () => {

  const {bloodSugarUnit, setBloodSugarUnit} = React.useContext(SettingsContext);
  const [bloodSugarUnitInput, setBloodSugarInput] = useState(bloodSugarUnit);

  useEffect(() => {
    if (null === bloodSugarUnitInput) {
      return;
    }

    setBloodSugarUnit(bloodSugarUnitInput);
  }, [bloodSugarUnitInput])

  return (
    <Box>
      <FormLabel id="blood-sugar-unit-radio-group-label"><FormattedMessage id="app.settings.bloodSugarUnit"/></FormLabel>
      <RadioGroup
        aria-labelledby="blood-sugar-unit-radio-group-label"
        value={bloodSugarUnitInput}
        onChange={e => setBloodSugarInput(e.target.value)}
      >
        <FormControlLabel value="Mmoll" control={<Radio/>} label={<FormattedMessage id="app.settings.mmoll"/>}/>
        <FormControlLabel value="Mgdl" control={<Radio/>} label={<FormattedMessage id="app.settings.mgdl"/>}/>
      </RadioGroup>
    </Box>
  );
};

export default BloodSugarUnitSettings;