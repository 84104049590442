import IntlModel from "../model/IntlModel";

class IntlRepository {

  private readonly localeKey = 'locale';

  getIntl(): IntlModel {
    const storageValue = localStorage.getItem(this.localeKey);
    if (storageValue) {
      return IntlModel.fromUnsafe(storageValue);
    }

    return IntlModel.fromUnsafe(navigator.language)
  }

  saveIntl(intl: IntlModel) {
    localStorage.setItem(this.localeKey, intl.locale);
  }
}

export default IntlRepository;
