import Ratio from "./Ratio/Ratio";
import CarbUnit from "./CarbUnit/CarbUnit";
import {parseInputToFloat} from "../utils/Number";
import BloodSugarUnit from "./BloodSugarUnit/BloodSugarUnit";

class SensitivityFactor {
  public readonly value;
  public readonly unit;

  constructor(value: number, unit: BloodSugarUnit) {
    this.value = Math.round((value + Number.EPSILON) * 1000) / 1000;
    this.unit = unit;
  }

  static fromInput(value: string, unit: BloodSugarUnit) {
    return new this(parseInputToFloat(value), unit)
  }

  static fromRatio(ratio: Ratio, carbUnit: CarbUnit, unit: BloodSugarUnit) {
    return new this((5 * carbUnit.gramAmount / ratio.use()) / unit.mgdlAmount, unit);
  }

  presentRounded() {
    return this.unit.presentRounded(this.value);
  }

  presentWithUnit() {
    return String(this.value) + ' ' + this.unit.symbol;
  }
}

export default SensitivityFactor;