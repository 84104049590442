class Precision {
  public readonly value;

  constructor(value: number) {
    this.value = value;
  }

  static fromInput(value: string) {
    return new this(parseFloat(value))
  }
}

export default Precision;