abstract class Ratio {

  readonly value;

  constructor(value: number) {
    this.value = value;
  }

  abstract presentWithSign(): string;

  abstract use(): number;
}


export default Ratio;
