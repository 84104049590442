import {Box} from "@mui/material";
import React from "react";
import InfoTooltip from "../Tooltip/InfoTooltip";
import {FormattedMessage} from "react-intl";

const RatioTypeLabel = ({carb, correction, total}) => {

  return (
    <InfoTooltip content={
      <React.Fragment>
        <Box><strong><FormattedMessage id="app.calculator.carb"/>: </strong>{carb}</Box>
        <Box py={1}><strong><FormattedMessage id="app.calculator.correction"/>: </strong>{correction}</Box>
        <Box><strong><FormattedMessage id="app.calculator.total"/>: </strong>{total}</Box>
      </React.Fragment>
    }/>
  );
};

export default RatioTypeLabel;