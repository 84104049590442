import InsulinDose from "./InsulinDose";
import SensitivityFactor from "./SensitivityFactor";
import BloodSugar from "./BloodSugar";
import Carb from "./Carb";
import Ratio from "./Ratio/Ratio";

export const explainTotal = (correctionInsulinDose: InsulinDose,
                             carbInsulinDose: InsulinDose,
                             totalInsulinDose: InsulinDose,
                             actualInsulinDose: InsulinDose) => {

  let result = carbInsulinDose.presentWithUnit();
  result += correctionInsulinDose.value < 0 ? ' - ' : ' + ';
  result += `${correctionInsulinDose.abs().presentWithUnit()} = ${totalInsulinDose.presentWithUnit()}`;

  if (totalInsulinDose.presentWithUnit() !== actualInsulinDose.presentWithUnit()) {
    result += ` ≈ ${actualInsulinDose.presentWithUnit()}`;
  }

  return result
};

export const explainCorrection = (sensitivityFactor: SensitivityFactor, target: BloodSugar, current: BloodSugar, correctionInsulinDose: InsulinDose) => {
  return `(${current.presentWithUnit()} - ${target.presentWithUnit()}) ÷ ${sensitivityFactor.presentWithUnit()} = ${correctionInsulinDose.presentWithUnit()}`;
};

export const explainCarbInsulinDose = (carb: Carb, carbUnitSymbol: string, ratio: Ratio, carbInsulinDose: InsulinDose) => {
  return `${String(carb.value) + ' ' + carbUnitSymbol } ${ratio.presentWithSign()} = ${carbInsulinDose.presentWithUnit()}`;
};
