import React, {useEffect, useState} from "react";
import CalculatorLogic from "../calculator/Calculator";
import Carb from "../calculator/Carb";
import BloodSugar from "../calculator/BloodSugar";
import Result from "./Result";
import {Grid, InputAdornment, TextField} from "@mui/material";
import Legend from "./Legend";
import SensitivityFactor from "../calculator/SensitivityFactor";
import Precision from "../calculator/Precision";
import {SettingsContext} from "../contexts/SettingsContext";
import {explainCarbInsulinDose, explainCorrection, explainTotal} from "../calculator/Explainer";
import CarbUnitFactory from "../calculator/CarbUnit/CarbUnitFactory";
import RatioFactoryFactory from "../calculator/RatioFactory/RatioFactoryFactory";
import {FormattedMessage, useIntl} from "react-intl";
import bloodSugarUnitFactory from "../calculator/BloodSugarUnit/BloodSugarUnitFactory";

const Calculator = () => {

  const intl = useIntl();

  const {bloodSugarUnit, precision, carbUnit, carbPortionAmount, ratioType, sensitivityFactorSuggestion: isSensitivityFactorSuggestion} = React.useContext(SettingsContext);

  const carbUnitObj = CarbUnitFactory.create(carbUnit, carbPortionAmount);
  const ratioFactory = RatioFactoryFactory.createFactory(ratioType);

  const bloodSugarUnitObj = bloodSugarUnitFactory.create(bloodSugarUnit);

  const precisionObj = Precision.fromInput(precision);
  const calculatorLogic = new CalculatorLogic(precisionObj);

  const [carb, setCarb] = useState('');
  const [ratio, setRatio] = useState('');
  const [sensitivityFactor, setSensitivityFactor] = useState('');
  const [sensitivityFactorSuggestion, setSensitivityFactorSuggestion] = useState('');
  const [target, setTarget] = useState('');
  const [current, setCurrent] = useState('');
  const [result, setResult] = useState([]);

  const calculateSensitivityFactorSuggestion = () => {
    let sensitivityFactorSuggestion = '';
    if ('' !== ratio && '0' !== ratio && '0.' !== ratio) {
      sensitivityFactorSuggestion = SensitivityFactor.fromRatio(ratioFactory.createFromInput(ratio), carbUnitObj, bloodSugarUnitObj).presentRounded();
    }
    setSensitivityFactorSuggestion(sensitivityFactorSuggestion);
  };

  const calculateResult = () => {
    const carbObj = Carb.fromInput(carb, carbUnitObj);
    const ratioObj = ratioFactory.createFromInput(ratio);
    const sensitivityFactorObj = SensitivityFactor.fromInput(sensitivityFactor || sensitivityFactorSuggestion, bloodSugarUnitObj);
    const targetObj = BloodSugar.fromInput(target, bloodSugarUnitObj);
    const currentObj = BloodSugar.fromInput(current, bloodSugarUnitObj);

    const meal = calculatorLogic.calculateMeal(carbObj, ratioObj, sensitivityFactorObj, targetObj, currentObj);

    const toResult = (key, meal, mealRounded) => {
      return {
        key: key,
        carb: mealRounded.carb.toString() + ' ' + intl.formatMessage({id: carbUnitObj.translatorId}),
        insulinDose: mealRounded.insulinDose.toString(precisionObj),
        carbExplanation: explainCarbInsulinDose(mealRounded.carb, intl.formatMessage({id: carbUnitObj.translatorId}), ratioObj, mealRounded.carbInsulinDose),
        correctionExplanation: explainCorrection(sensitivityFactorObj, targetObj, currentObj, meal.correctionInsulinDose),
        totalExplanation: explainTotal(meal.correctionInsulinDose, mealRounded.carbInsulinDose, mealRounded.totalInsulinDose, mealRounded.insulinDose)
      };
    };

    if (meal.floor.carb.toString() === meal.ceil.carb.toString()) {
      setResult([toResult(1, meal, meal.floor)]);

      return;
    }

    if (meal.floor.carb.toString() === carbObj.toString()) {
      setResult([toResult(1, meal, meal.floor)]);

      return;
    }

    if (meal.ceil.carb.toString() === carbObj.toString()) {
      setResult([toResult(1, meal, meal.ceil)]);

      return;
    }

    setResult([toResult(1, meal, meal.floor), toResult(2, meal, meal.ceil)]);
  };

  useEffect(() => {
    if (isSensitivityFactorSuggestion) {
      calculateSensitivityFactorSuggestion();
    }
  }, [ratio]);

  useEffect(() => {
    if (!carb || !ratio || !(sensitivityFactor || sensitivityFactorSuggestion) || !target || !current) {
      setResult([]);

      return;
    }
    calculateResult();
  }, [carb, ratio, sensitivityFactor, sensitivityFactorSuggestion, target, current]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label={<FormattedMessage id="app.calculator.carb"/>}
          name="carb" value={carb}
          onChange={e => setCarb(e.target.value)}
          inputProps={{
            inputMode: 'decimal',
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{intl.formatMessage({id: carbUnitObj.translatorId})}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={<FormattedMessage id="app.calculator.ratio"/>}
          name="ratio" value={ratio}
          onChange={e => setRatio(e.target.value)}
          inputProps={{
            inputMode: 'decimal',
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{ratioFactory.presentSign()}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={<FormattedMessage id="app.calculator.sensitivityFactor"/>}
          name="sensitivityFactor"
          value={sensitivityFactor}
          placeholder={sensitivityFactorSuggestion}
          onChange={e => setSensitivityFactor(e.target.value)}
          inputProps={{
            inputMode: 'numeric',
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{bloodSugarUnitObj.symbol}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={<FormattedMessage id="app.calculator.target"/>}
          name="target"
          value={target}
          onChange={e => setTarget(e.target.value)}
          inputProps={{
            inputMode: 'numeric',
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{bloodSugarUnitObj.symbol}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={<FormattedMessage id="app.calculator.current"/>}
          name="current" value={current}
          onChange={e => setCurrent(e.target.value)}
          inputProps={{
            inputMode: 'numeric',
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{bloodSugarUnitObj.symbol}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Result result={result}></Result>
      </Grid>
      {result.length > 0 && <Grid item xs={12}>
        <Legend carbUnit={intl.formatMessage({id: carbUnitObj.translatorId})} insulinUnit={"IU"}></Legend>
      </Grid>}
    </Grid>
  );
}

export default Calculator;
