import BloodSugarUnit from "./BloodSugarUnit";

class Mgdl extends BloodSugarUnit {

  readonly symbol = "mg/dL";

  readonly mgdlAmount = 1;

  presentRounded(value: number): string {
    return String(Math.round(value));
  }
}

export default Mgdl;