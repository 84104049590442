import Ratio from "./Ratio";

class DivideRatio extends Ratio {
  static readonly sign = '÷';

  presentWithSign() {
    return DivideRatio.sign + ' ' + String(this.value);
  }

  use(): number {
    return 1 / this.value;
  }
}

export default DivideRatio;