import React, {useEffect, useState} from "react";
import {SettingsContext} from "../../contexts/SettingsContext";
import {Box, FormControlLabel, FormLabel, Radio, RadioGroup, Slider, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";

const CarbUnitSettings = () => {

  const {carbUnit, setCarbUnit, carbPortionAmount, setCarbPortionAmount} = React.useContext(SettingsContext);
  const [carbUnitInput, setCarbUnitInput] = useState(carbUnit);
  const [carbPortionAmountInput, setCarbPortionAmountInput] = useState(carbPortionAmount);

  useEffect(() => {
    if (null === carbUnitInput) {
      return;
    }
    setCarbUnit(carbUnitInput);
  }, [carbUnitInput])

  useEffect(() => {
    if (null === carbPortionAmountInput) {
      return;
    }
    setCarbPortionAmount(carbPortionAmountInput);
  }, [carbPortionAmountInput])

  return (
    <Box>
      <FormLabel id="carbohydrates-unit-radio-group-label"><FormattedMessage id="app.settings.carbUnit"/></FormLabel>
      <RadioGroup
        aria-labelledby="carbohydrates-unit-radio-group-label"
        value={carbUnitInput}
        onChange={e => setCarbUnitInput(e.target.value)}
      >
        <FormControlLabel value="Gram" control={<Radio/>} label={<FormattedMessage id="app.settings.gramWithSymbol"/>}/>
        <FormControlLabel value="Portion" control={<Radio/>} label={<FormattedMessage id="app.settings.carbUnitWithSymbol"/>}/>
      </RadioGroup>
      {'Portion' === carbUnitInput && <Box>
        <Typography gutterBottom align="center">
          1 <FormattedMessage id="app.calculator.carbUnitSymbol"/> = {carbPortionAmountInput} <FormattedMessage id="app.calculator.gramSymbol"/>
        </Typography>
        <Slider
          aria-label="1 portion is x grams"
          min={1}
          max={25}
          value={carbPortionAmountInput}
          onChange={e => setCarbPortionAmountInput(e.target.value)}
        ></Slider>
      </Box>}
    </Box>
  );
};

export default CarbUnitSettings;