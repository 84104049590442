import CarbUnit from "./CarbUnit";

class Gram extends CarbUnit {

  readonly gramAmount = 1;

  readonly translatorId = "app.calculator.gramSymbol";

  presentValue(value: number): string {
    return String(value);
  }

  roundValue(value: number): number {
    return Math.round(value + Number.EPSILON);
  }
}

export default Gram;