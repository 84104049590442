import CarbUnit from "./CarbUnit";

class Portion extends CarbUnit {

  readonly gramAmount;

  readonly translatorId = "app.calculator.carbUnitSymbol";

  constructor(gramAmount: number) {
    super();
    this.gramAmount = gramAmount;
  }

  presentValue(value: number): string {
    return value.toFixed(1);
  }

  roundValue(value: number): number {
    return Math.round((value + Number.EPSILON) * 10) / 10;
  }
}

export default Portion;