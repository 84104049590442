import React, {useEffect, useState} from "react";
import {Box, InputAdornment, TextField} from "@mui/material";
import {SettingsContext} from "../../contexts/SettingsContext";
import {FormattedMessage} from "react-intl";
import InfoTooltip from "../Tooltip/InfoTooltip";
import {parseInputToFloat} from "../../utils/Number";

const PrecisionSettings = () => {

  const {precision, setPrecision} = React.useContext(SettingsContext);
  const [precisionInput, setPrecisionInput] = useState(null === precision ? '' : String(precision));

  useEffect(() => {
    setPrecision('' === precisionInput ? null : parseInputToFloat(precisionInput));
  }, [precisionInput])

  return (
    <TextField
      onChange={e => setPrecisionInput(e.target.value)}
      label={<FormattedMessage id="app.calculator.insulinPrecision"/>}
      name="precision"
      value={precisionInput}
      inputProps={{
        inputMode: 'decimal',
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          IU
          <Box sx={{mb: 0.5, mr: -1, whiteSpace: "pre-wrap"}}><InfoTooltip content={
            <FormattedMessage id="app.calculator.insulinPrecision.tooltip"/>
          }/></Box>
        </InputAdornment>,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default PrecisionSettings;