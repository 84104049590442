abstract class CarbUnit {

  abstract readonly gramAmount: number;

  abstract readonly translatorId: string;

  abstract presentValue(value: number): string;

  abstract roundValue(value: number): number;
}

export default CarbUnit;
