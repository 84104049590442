import Ratio from "../Ratio/Ratio";

abstract class RatioFactory {

  abstract createFromInput(value: string): Ratio;

  abstract presentSign(): string;

}

export default RatioFactory;
