import * as React from 'react';
import {Box, ClickAwayListener, IconButton, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import './InfoTooltip.css';

const InfoTooltip = ({content}) => {

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <Box className="info-tooltip" sx={{pl: 0.5}}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={content}
        >
          <IconButton onClick={handleTooltipOpen} sx={{backgroundColor: "inherit"}}>
            <InfoIcon></InfoIcon>
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default InfoTooltip;