import RatioFactory from "./RatioFactory";
import DivideRatio from "../Ratio/DivideRatio";
import {parseInputToFloat} from "../../utils/Number";

class DivideRatioFactory extends RatioFactory {

  createFromInput(value: string): DivideRatio {
    return new DivideRatio(parseInputToFloat(value));
  }

  presentSign(): string {
    return DivideRatio.sign;
  }

}

export default DivideRatioFactory;