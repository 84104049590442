import React, {useState} from 'react';
import {IntlProvider as ReactIntlProvider} from 'react-intl';
import IntlRepository from "../repository/IntlRepository";
import IntlModel from "../model/IntlModel";

export const IntlContext = React.createContext();

const repository = new IntlRepository();
const model = repository.getIntl();
repository.saveIntl(model)

export const IntlProvider = (props) => {
  const [locale, setLocale] = useState(model.locale);
  const [messages, setMessages] = useState(model.messages);

  function selectLocale(newLocale) {
    const intlModel = IntlModel.fromUnsafe(newLocale)

    setLocale(intlModel.locale);
    setMessages(intlModel.messages)

    repository.saveIntl(intlModel)
  }

  return (
    <IntlContext.Provider value={{locale, selectLocale}}>
      <ReactIntlProvider messages={messages} locale={locale}>
        {props.children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
}