import {Grid} from "@mui/material";
import React from "react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ExplanationTooltip from "./Tooltip/ExplanationTooltip";
import CarbChip from "./Chip/CarbChip";
import InsulinChip from "./Chip/InsulinChip";

const Result = ({result}) => {
  return (
    <Grid
      container
      sx={{
        height: 100,
        py: 0.5,
        justifyContent: "center",
        border: 1,
        borderRadius: 1,
        borderColor: 'rgb(0 0 0 / 16%)',
        bgcolor: 'rgb(0 0 0 / 4%)'
      }}>
      {result.map(({key, carb, insulinDose, correctionExplanation, carbExplanation, totalExplanation}) => {
        return (
          <Grid item key={key} sx={{display: "flex", alignItems: "center", pl: 0.75, pr: 0}}>
            <CarbChip text={carb} display="inline-block"></CarbChip>
            <ArrowRightAltIcon></ArrowRightAltIcon>
            <InsulinChip text={insulinDose} display="inline-block"></InsulinChip>
            <ExplanationTooltip
              correction={correctionExplanation}
              carb={carbExplanation}
              total={totalExplanation}
            ></ExplanationTooltip>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Result;