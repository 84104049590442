abstract class BloodSugarUnit {

  abstract readonly symbol: string;

  abstract readonly mgdlAmount: number;

  abstract presentRounded(value: number): string;

}

export default BloodSugarUnit;