import React from "react";
import {Box} from "@mui/material";
import CarbChip from "./Chip/CarbChip";
import InsulinChip from "./Chip/InsulinChip";
import {useIntl} from "react-intl";

const Legend = ({carbUnit, insulinUnit}) => {

  const intl = useIntl();

  return (
    <Box>
      <CarbChip text={`${intl.formatMessage({id: "app.calculator.carb"})} (${carbUnit})`} display="block"></CarbChip>
      <Box sx={{mb: 1}}></Box>
      <InsulinChip text={`${intl.formatMessage({id: "app.calculator.insulin"})} (${insulinUnit})`} display="block"></InsulinChip>
    </Box>
  );
};

export default Legend;