export const roundFloorByStep = (value: number, step: number) => {
  const inv = 1.0 / step;
  return Math.floor(value * inv) / inv;
};

export const roundCeilByStep = (value: number, step: number) => {
  const inv = 1.0 / step;
  return Math.ceil(value * inv) / inv;
};

export const countDecimals = (value: number) => {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  return value.toString().split(".")[1].length || 0;
}
