import MultiplyRatioFactory from "./MultiplyRatioFactory";
import RatioFactory from "./RatioFactory";
import DivideRatioFactory from "./DivideRatioFactory";

class RatioFactoryFactory {

  static createFactory(type: string): RatioFactory {
    switch (type) {
      case 'MultiplyRatio':
        return new MultiplyRatioFactory();
      case 'DivideRatio':
        return new DivideRatioFactory();
      default:
        throw new Error(`Unsupported ratio type "${type}"`);
    }
  }
}


export default RatioFactoryFactory;
