import React, {useEffect, useState} from "react";
import {Box, Checkbox, FormLabel} from "@mui/material";
import {SettingsContext} from "../../contexts/SettingsContext";
import {FormattedMessage} from "react-intl";

const SensitivityFactorSuggestion = () => {

  const {sensitivityFactorSuggestion, setSensitivityFactorSuggestion} = React.useContext(SettingsContext);
  const [sensitivityFactorSuggestionInput, setSensitivityFactorSuggestionInput] = useState(sensitivityFactorSuggestion);

  useEffect(() => {
    setSensitivityFactorSuggestion(sensitivityFactorSuggestionInput);
  }, [sensitivityFactorSuggestionInput])

  return (
    <Box>
      <FormLabel id="sensitivity-factor-suggestion-label"><FormattedMessage id="app.settings.sensitivityFactorSuggestion"/></FormLabel>
      <Checkbox
        checked={sensitivityFactorSuggestionInput}
        onChange={e => setSensitivityFactorSuggestionInput(e.target.checked)}
        inputProps={{'aria-label': 'sensitivity factor suggestion'}}
      />
    </Box>
  );
};

export default SensitivityFactorSuggestion;