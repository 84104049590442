import {countDecimals, roundCeilByStep, roundFloorByStep} from "../utils/Math";
import Carb from "./Carb";
import Ratio from "./Ratio/Ratio";
import SensitivityFactor from "./SensitivityFactor";
import BloodSugar from "./BloodSugar";
import Precision from "./Precision";

class InsulinDose {
  public readonly value;

  constructor(value: number) {
    this.value = Math.round((value + Number.EPSILON) * 1000) / 1000;
  }

  static calculateCarbInsulinDose(carb: Carb, ratio: Ratio) {
    return new this(carb.value * ratio.use());
  }

  static calculateCorrectionInsulinDose(sensitivityFactor: SensitivityFactor, target: BloodSugar, current: BloodSugar) {
    if (0 === sensitivityFactor.value) {
      return new this(0);
    }

    const excess = current.value - target.value;

    return new this(excess / sensitivityFactor.value);
  }

  roundFloor(precision: Precision) {
    return new InsulinDose(roundFloorByStep(this.value, precision.value));
  }

  roundCeil(precision: Precision) {
    return new InsulinDose(roundCeilByStep(this.value, precision.value));
  }

  add(insulinDose: InsulinDose) {
    return new InsulinDose(this.value + insulinDose.value);
  }

  subtract(insulinDose: InsulinDose) {
    return new InsulinDose(this.value - insulinDose.value)
  }

  toString = (precision: Precision) => {
    return this.value.toFixed(countDecimals(precision.value))
  }

  presentRoundedWithUnit() {
    return String(this.value) + ' ' + this.unit;
  }

  presentWithUnit() {
    return String(this.value) + ' ' + this.unit;
  }

  abs() {
    return new InsulinDose(Math.abs(this.value));
  }


  get unit() {
    return 'IU';
  }
}

export default InsulinDose;