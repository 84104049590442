import Mmoll from "./Mmoll";
import Mgdl from "./Mgdl";
import BloodSugarUnit from "./BloodSugarUnit";

class BloodSugarUnitFactory {

  static create(name: string): BloodSugarUnit {
    switch (name) {
      case 'Mmoll':
        return new Mmoll();
      case 'Mgdl':
        return new Mgdl();
      default:
        throw new Error(`Unsupported blood sugar unit with name "${name}"`);
    }
  }
}

export default BloodSugarUnitFactory;