import {Box} from "@mui/material";
import React from "react";

const InsulinChip= ({text, display}) => {
  return (
    <Box
      display={display}
      align="center"
      sx={{bgcolor: "rgba(0,170,222,0.4)", px: 0.75, py: 0.5, pb: 0.25, border: 2, borderColor: 'rgb(0 0 0 / 16%)', minWidth: "32px"}}
    >
      {text}
    </Box>
  );
};

export default InsulinChip;