import Portion from "./Portion";
import Gram from "./Gram";
import CarbUnit from "./CarbUnit";

class CarbUnitFactory {

  static create(name: string, amount: number): CarbUnit {
    switch (name) {
      case 'Gram':
        return new Gram();
      case 'Portion':
        return new Portion(amount);
      default:
        throw new Error(`Unsupported carb unit with name "${name}"`);
    }
  }
}

export default CarbUnitFactory;