import React, {useEffect, useState} from "react";
import {SettingsContext} from "../../contexts/SettingsContext";
import {Box, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import DivideRatioTypeLabel from "../RatioTypeLabel/DivideRatioTypeLabel";
import MultiplyRatioTypeLabel from "../RatioTypeLabel/MultiplyRatioTypeLabel";
import {FormattedMessage} from "react-intl";

const RatioTypeSettings = () => {

  const {ratioType, setRatioType} = React.useContext(SettingsContext);
  const [ratioTypeInput, setRatioTypeInput] = useState(ratioType);

  useEffect(() => {
    if (null === ratioTypeInput) {
      return;
    }
    setRatioType(ratioTypeInput);
  }, [ratioTypeInput])

  return (
    <Box>
      <FormLabel id="ratio-type-radio-group-label"><FormattedMessage id="app.settings.radioType"/></FormLabel>
      <Box sx={{mt: 1, pl: 2}}>
        <Box sx={{pl: 3}}><FormattedMessage id="app.calculator.carb"/></Box>
        <RadioGroup
          aria-labelledby="ratio-type-radio-group-label"
          value={ratioTypeInput}
          onChange={e => setRatioTypeInput(e.target.value)}
        >
          <FormControlLabel value="DivideRatio" control={<Radio />} label={<DivideRatioTypeLabel/>} />
          <FormControlLabel value="MultiplyRatio" control={<Radio />} label={<MultiplyRatioTypeLabel/>} />
        </RadioGroup>
        <Box sx={{pl: 3}}><FormattedMessage id="app.calculator.ratio"/></Box>
      </Box>
    </Box>
  );
};

export default RatioTypeSettings;