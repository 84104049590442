import {Box} from "@mui/material";
import NavBar from "./../component/NavBar";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {useIntl} from "react-intl";

function Root() {

  const intl = useIntl();

  useEffect(() => {
    document.title = `${intl.formatMessage({id: "app.page.title"})} | InsulinMath`;
  });

  return (
    <div className="App">
      <NavBar></NavBar>
      <Box display="flex" justifyContent="center" sx={{my: 3}}>
        <Box maxWidth={210}>
          <Outlet/>
        </Box>
      </Box>
    </div>
  );
}

export default Root;
