class SettingsRepository {

  private readonly precisionKey = 'settings.precision';
  private readonly carbUnitKey = 'settings.carbUnit';
  private readonly carbPortionAmountKey = 'settings.carbPortionAmount';
  private readonly ratioTypeKey = 'settings.ratioType';
  private readonly sensitivityFactorSuggestionKey = 'settings.sensitivityFactorSuggestion';
  private readonly bloodSugarUnitKey = 'settings.bloodSugarUnit';

  getPrecision() {
    const precision = localStorage.getItem(this.precisionKey);
    if ('' === precision || null === precision) {
      return null;
    }

    return +precision;
  }

  savePrecision(precision: number | null) {
    if (null === precision) {
      localStorage.removeItem(this.precisionKey);

      return;
    }

    localStorage.setItem(this.precisionKey, String(precision));
  }

  getCarbUnit() {
    const carbUnit = localStorage.getItem(this.carbUnitKey);
    if ('' === carbUnit) {
      return null;
    }

    return carbUnit
  }

  saveCarbUnit(carbUnit: string|null) {
    if (null === carbUnit) {
      localStorage.removeItem(this.carbUnitKey);

      return;
    }

    localStorage.setItem(this.carbUnitKey, carbUnit);
  }

  getCarbPortionAmount() {
    const amount = localStorage.getItem(this.carbPortionAmountKey);
    if ('' === amount || null === amount) {
      return 10;
    }

    return +amount;
  }

  saveCarbPortionAmount(portionAmount: number|null) {
    if (null === portionAmount) {
      localStorage.removeItem(this.carbPortionAmountKey);

      return;
    }

    localStorage.setItem(this.carbPortionAmountKey, String(portionAmount));
  }


  getRatioType() {
    const radioType = localStorage.getItem(this.ratioTypeKey);
    if ('' === radioType) {
      return null;
    }

    return radioType
  }

  saveRatioType(carbUnit: string|null) {
    if (null === carbUnit) {
      localStorage.removeItem(this.carbUnitKey);

      return;
    }

    localStorage.setItem(this.ratioTypeKey, carbUnit);
  }

  getSensitivityFactorSuggestion() {
    return '1' === localStorage.getItem(this.sensitivityFactorSuggestionKey);
  }

  saveSensitivityFactorSuggestion(sensitivityFactorSuggestion: boolean) {
    if (!sensitivityFactorSuggestion) {
      localStorage.removeItem(this.sensitivityFactorSuggestionKey);

      return;
    }

    localStorage.setItem(this.sensitivityFactorSuggestionKey, '1');
  }

  getBloodSugarUnit() {
    const bloodSugarUnit = localStorage.getItem(this.bloodSugarUnitKey);
    if ('' === bloodSugarUnit) {
      return null;
    }

    return bloodSugarUnit
  }

  saveBloodSugarUnit(bloodSugarUnit: string|null) {
    if (null === bloodSugarUnit) {
      localStorage.removeItem(this.bloodSugarUnitKey);

      return;
    }

    localStorage.setItem(this.bloodSugarUnitKey, bloodSugarUnit);
  }
}

export default SettingsRepository;
