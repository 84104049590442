import Carb from "./Carb";
import InsulinDose from "./InsulinDose";
import Ratio from "./Ratio/Ratio";
import SensitivityFactor from "./SensitivityFactor";
import BloodSugar from "./BloodSugar";
import Precision from "./Precision";

class Calculator {
  private readonly precision;

  constructor(precision: Precision) {
    this.precision = precision;
  }

  calculateMeal(carb: Carb, ratio: Ratio, sensitivityFactor: SensitivityFactor, target: BloodSugar, current: BloodSugar) {

    const carbInsulinDose = InsulinDose.calculateCarbInsulinDose(carb, ratio);
    const correctionInsulinDose = InsulinDose.calculateCorrectionInsulinDose(sensitivityFactor, target, current);

    let insulinDose = carbInsulinDose.add(correctionInsulinDose);
    if (insulinDose.value < 0) {
      insulinDose = new InsulinDose(0);
    }

    const floorInsulinDose = insulinDose.roundFloor(this.precision);
    const ceilInsulinDose = insulinDose.roundCeil(this.precision);

    const floorCarb = Carb.calculate(
      floorInsulinDose.subtract(correctionInsulinDose), ratio, carb.unit
    ).round();
    const ceilCarb = Carb.calculate(
      ceilInsulinDose.subtract(correctionInsulinDose), ratio, carb.unit
    ).round()

    const floorCarbInsulinDose = InsulinDose.calculateCarbInsulinDose(floorCarb, ratio);
    const ceilCarbInsulinDose = InsulinDose.calculateCarbInsulinDose(ceilCarb, ratio);

    const totalFloorCarbInsulinDose = floorCarbInsulinDose.add(correctionInsulinDose);
    const totalCeilCarbInsulinDose = ceilCarbInsulinDose.add(correctionInsulinDose);

    return {
      correctionInsulinDose: correctionInsulinDose,
      floor: {
        carb: floorCarb,
        carbInsulinDose: floorCarbInsulinDose,
        totalInsulinDose: totalFloorCarbInsulinDose,
        insulinDose: floorInsulinDose,
      },
      ceil: {
        carb: ceilCarb,
        carbInsulinDose: ceilCarbInsulinDose,
        totalInsulinDose: totalCeilCarbInsulinDose,
        insulinDose: ceilInsulinDose,
      }
    }
  }
}

export default Calculator;
