import RatioTypeLabel from "./RatioTypeLabel";
import {FormattedMessage} from "react-intl";
import React from "react";

const DivideRatioTypeLabel = () => {
  return (
    <RatioTypeLabel
      sign="×"
      tooltipContent={<FormattedMessage id="app.settings.multiplyRatio.tooltip"/>}
    />
  );
};

export default DivideRatioTypeLabel;