import Ratio from "./Ratio/Ratio";
import InsulinDose from "./InsulinDose";
import CarbUnit from "./CarbUnit/CarbUnit";
import {parseInputToFloat} from "../utils/Number";

class Carb {
  public readonly value;
  public readonly unit;

  constructor(value: number, unit: CarbUnit) {
    this.value = Math.round((value + Number.EPSILON) * 1000) / 1000;
    this.unit = unit;
  }

  static fromInput(value: string, unit: CarbUnit) {
    return new this(parseInputToFloat(value), unit)
  }

  static calculate(insulinDose: InsulinDose, ratio: Ratio, unit: CarbUnit) {
    return new this(insulinDose.value / ratio.use(), unit);
  }

  round() {
    return new Carb(this.unit.roundValue(this.value), this.unit);
  }

  toString() {
    return this.unit.presentValue(this.value);
  }
}

export default Carb;