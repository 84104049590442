import React from "react";
import {Button, Grid,} from "@mui/material";
import {SettingsContext} from "../../contexts/SettingsContext";
import PrecisionSettings from ".././Settings/PrecisionSettings";
import CarbUnitSettings from "./CarbUnitSettings";
import RatioTypeSettings from "./RatioTypeSettings";
import SensitivityFactorSuggestionSettings from "./SensitivityFactorSuggestionSettings";
import LocaleSelector from "./LocaleSelector";
import {FormattedMessage} from "react-intl";
import BloodSugarUnitSettings from "./BloodSugarUnitSettings";

const Settings = () => {

  const {setShow, isComplete} = React.useContext(SettingsContext);

  const closeSettings = () => {
    setShow(false);
  };

  return (
    <Grid container spacing={2} maxWidth={230}>
      <Grid item xs={12}>
        <LocaleSelector/>
      </Grid>
      <Grid item xs={12}>
        <BloodSugarUnitSettings/>
      </Grid>
      <Grid item xs={12}>
        <CarbUnitSettings/>
      </Grid>
      <Grid item xs={12}>
        <RatioTypeSettings/>
      </Grid>
      <Grid item xs={12}>
        <PrecisionSettings/>
      </Grid>
      <Grid item xs={12}>
        <SensitivityFactorSuggestionSettings/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Button disabled={!isComplete} onClick={closeSettings} variant="contained" color="success">
          <FormattedMessage id="app.settings.doneButton"/>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Settings;