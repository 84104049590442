import {createBrowserRouter} from "react-router-dom";
import Root from "./routes/root";
import Index from "./routes";
import LocaleGuard, {LocaleGuardLoader} from "./component/LocaleGuard";
import Error from "./routes/error";

const router = createBrowserRouter([
  {
    path: "/:locale?",
    element: <LocaleGuard><Root/></LocaleGuard>,
    errorElement: <Error/>,
    loader: LocaleGuardLoader,
    children: [
      {
        index: true,
        element: <Index/>
      },
      {
        path: "test1",
        element: <div>test one</div>,
      },
      {
        path: "test2",
        element: <div>test two</div>,
      },
    ]
  },
]);

export default router;